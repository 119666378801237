/** all frame report card */
.bonus-report-card-container {
  position: relative;
  width: 2480px;
  height: 3508px;

  .report-frame {
    width: '100%';
    height: '100%';
    z-index: 10;
    position: absolute;
  }

  .bonus-report-info-container {
    z-index: 25;
    .agent-profile-container {
      position: absolute;
      z-index: -1;
      width: 915px;
      height: 1200px;
      top: 28%;
      left: 51%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      .agent-profile {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 11.5%;
      }
    }

    .bonus-month {
      position: absolute;
      font-family: 'BebasNeue_Bold';
      top: 9.6%;
      left: 26%;
      // transform: translate(-50%, -50%);
      z-index: inherit;
      color: #ffffff;
      font-size: 102px;
      // -webkit-text-stroke: #000 2px;
      width: 90rem;
      text-align: center;
      height: 8rem;
      line-height: 118px;
      letter-spacing: 2px;
      font-weight: 700;
      // text-transform: uppercase;
    }

    .bonus-level {
      position: absolute;
      font-family: 'Berlin-Sans-Fb';
      top: 7.3%;
      left: 18.5%;
      // transform: translate(-50%, -50%);
      z-index: inherit;
      font-size: 97px;
      width: 100rem;
      height: 8rem;
      // font-weight: 100;
      text-align: center;
      letter-spacing: 2px;
      // line-height: 130px;
      text-transform: uppercase;
      color: #ecd4b6;
    }

    .agent-code {
      position: absolute;
      z-index: 10;
      top: 32.5%;
      left: 24%;
      transform: translate(-50%, -50%);
      font-size: 93px;
      font-family: 'Arimo-Regular';
      width: 305px;
      height: 100px;
      text-align: center;
      color: #ecd4b6;
      font-weight: 400;
      letter-spacing: 2px;
      &.prefix-name {
        top: 53.5%;
        left: 51%;
        width: auto;
        height: auto;
        font-weight: 400;
        color: #ecd4b6;
        // letter-spacing: 2px;
        // font-style: italic;
        // background: linear-gradient(to bottom,#feef9e 40%,#c7982e 80%);
        font-size: 93px;
        font-family: 'Arimo-Regular';
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }
    }

    .agent-name {
      position: absolute;
      z-index: inherit;
      top: 50.8%;
      transform: translate(0, -85%);
      font-family: 'Beautyful_comethrue_bold';
      display: flex;
      justify-content: center;
      align-items: center;
      width: 92%;
      height: 180px;
      text-align: center;
      left: 5.3%;
      font-weight: bold;
      color: #ecd4b6;
      // letter-spacing: 4px;
      font-size: 173px;
    }

    .award-amount {
      position: absolute;
      z-index: inherit;
      top: 56.5%;
      left: 15%;
      width: 120rem;
      height: 10rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'BMKing001-Regular';
      color: #ffffff;
      letter-spacing: 5px;
      font-size: 98px;
      letter-spacing: 2px;
      font-weight: bold;
      // font-weight: bold;
      // font-style: italic;
      .award-hyphen {
        width: 100px;
        position: absolute;
        transform: translate(27%, 1%);
      }
      .amount-value {
        margin-left: 5%;
      }
    }

    .product-wrapper {
      z-index: 12;
      position: absolute;
      width: 100%;
      height: 200px;
      top: 62%;
      left: 5%;
      display: flex;
      justify-content: center;
      .bonus-product-list {
        // position: absolute;
        z-index: 12;
        // transform: translate(-50%, 15%);
        // width: 2300px;
        // height: 1140px;
        font-family: 'Ace';
        color: #F4CE9F;
        // font-weight: 600;
        // line-height: 100px;
        letter-spacing: 1px;
        .product-info-row {
          .product-name {
            text-align: left;
          }
        }
        &.less_fiften {
          margin-top: 1%;
          // line-height: 100px;
          td {
            font-size: 53px !important;
            color: #F4CE9F !important;
            text-wrap: nowrap !important;
          }
        }
        &.more_fiften {
          margin-top: 1%;
          // line-height: 100px;
          td {
            color: #F4CE9F !important;
            font-size: 38px !important;
            text-wrap: nowrap !important;
          }
        }
      }
    }
  }
}

/** product report card */
.product_report_card_container {
  position: relative;
  width: 2480px;
  height: 3508px;
  .report_frame {
    width: '100%';
    height: '100%';
    z-index: 10;
    position: absolute;
  }

  .agent_profile {
    position: absolute;
    width: 858px;
    height: 1202px;
    top: 18%;
    left: 32.9%;
    z-index: -1;
    object-fit: cover;
  }
  .bonus_report_info_container {
    z-index: 25;

    .bonus_month {
      position: absolute;
      // top: 10.8%;
      left: 20.5%;
      font-size: 83px;
      letter-spacing: -5px;
      z-index: inherit;
      font-family: 'poppins_regular';
      color: #3e3e3e;
      width: 1500px;
      height: 120px;
      display: flex;
      justify-content: center;
      font-weight: 700;
    }

    .bonus_level {
      position: absolute;
      top: 13.4%;

      left: 20%;
      width: 1500px;
      height: 120px;
      display: flex;
      justify-content: center;
      z-index: inherit;
      font-size: 83px;
      letter-spacing: -5px;
      font-family: 'poppins_regular';
      color: #3e3e3e;
      font-weight: 700;
    }

    .agent_name {
      position: absolute;
      z-index: inherit;
      top: 52.5%;
      left: auto;
      font-family: 'BerkshireSwash-Regular';
      letter-spacing: -9px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 200px;
      color: #3e3e3e;
      font-weight: 500;
      font-size: 146px;
      // text-shadow: -10px 10px 0 #fff, 10px 10px 0 #fff, 0 10px 0 #fff, -10px 0 0 #fff, 10px 0 0 #fff,
      //   0 -10px 0 #fff, -10px -10px 0 #fff, 10px -10px 0 #fff;
    }

    .agent_code {
      position: absolute;
      z-index: inherit;
      top: 58.5%;
      left: 41%;
      font-size: 90px;
      letter-spacing: -5px;
      text-align: center;
      color: #3e3e3e;
      font-weight: 600;
      font-family: 'poppins_regular';
      // text-shadow: -8px -8px 0 #fff, 0 -8px 0 #fff, 8px -8px 0 #fff, 8px 0 0 #fff, 8px 8px 0 #fff,
      //   0 8px 0 #fff, -8px 8px 0 #fff, -8px 0 0 #fff,0 0 30px #000;
    }

    .bonus_qty_container {
      position: absolute;
      z-index: inherit;
      bottom: 13.4%;
      left: 44%;
      font-family: 'Aleo_Medium';
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 380px;
      height: 380px;
      color: #ffffff;
      line-height: 90px;
      font-weight: 400;
      .bonus_month_quantity {
        font-size: 109px;
        margin: 0;
        line-height: 100px;
        letter-spacing: -7px;
      }
      .bonus_quantity {
        font-size: 65px;
        margin: 0;
        letter-spacing: -4px;
      }
    }

    .award_amount {
      position: absolute;
      z-index: inherit;
      bottom: 9.3%;
      right: 21.5%;
      font-size: 82px;
      font-weight: 1000;
      letter-spacing: 2px;
      font-family: 'bmking010_bold';
      color: #000000;
    }
    .award_currency {
      position: absolute;
      z-index: inherit;
      font-size: 73px;
      font-family: 'bmking010_bold';
      text-indent: 7px;
      color: #000000;
      top: 17px;
    }

    .money_image {
      position: absolute;
      z-index: inherit;
      bottom: 13.29%;
      right: 16.5%;
      width: 400px;
      height: 200px;
      transform: rotateX(45deg);
    }
  }

  .foc_img_container {
    position: absolute;
    z-index: 25;
    left: 7.6%;
    bottom: 9%;
    width: 150px;
    height: 150px;
    font-family: 'Aleo_Medium';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.snail_wish {
      left: 34.3%;
      bottom: 14.8%;
    }
    &.new_skiin {
      left: 30.8%;
      bottom: 15.8%;
    }
  }

  .foc_img {
    width: 500px;
    height: 500px;
  }

  .foc_amount_image_container {
    position: absolute;
    z-index: 25;
    left: 10%;
    bottom: 0px;
    width: 250px;
  }

  .foc_name {
    position: absolute;
    z-index: inherit;
    left: 31%;
    bottom: 36.5%;
    font-size: 27px;
    font-family: 'Aleo_Medium';
    color: #ffffff;
    font-weight: 500;
    letter-spacing: -2px;
  }

  .foc_amount {
    position: absolute;
    width: 110px;
    height: 80px;
    left: 11%;
    top: 55.5%;
    font-size: 27px;
    font-family: 'Aleo_Medium';
    color: #ffffff;
    display: flex;
    justify-content: center;
    letter-spacing: -2px;
    font-weight: 500;
  }
}
