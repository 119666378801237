/** all frame report card */
.six_months_bonus_report_card_container {
  position: relative;
  width: 2480px;
  height: 3508px;

  .report_frame {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
  }

  .bonus_info_container{
    z-index: 25;
    .bonus_month {
      position: absolute;
      z-index: inherit;
      font-family: 'AbrilFatface_Regular';
      font-size: 83px;
      top: 10.5%;
      left: 19.8%;
      color: #dcb444;
      font-weight: 400;
      width: 1500px;
      height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .bonus_level {
      position: absolute;
      z-index: inherit;
      font-family: 'Berlin_Sans_Regular';
      font-size: 89px;
      text-transform: uppercase;
      color: #000000;
      top: 61.6%;
      left: -6.2%;
      // font-weight: 400;
      word-spacing: 0.1rem;
      width: 1500px;
      height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .agent_profile {
      position: absolute;
      z-index: -1;
      width: 830px;
      height: 1148px;
      object-fit: cover;
      top: 27.2%;
      left: 7.3%;
    }

    .bonus_less_ten_products {
      z-index: 25;
      // .agent_profile {
      //   position: absolute;
      //   z-index: -1;
      //   width: 784px;
      //   height: 1140px;
      //   top: 18.5%;
      //   left: 34.2%;
      // }

      // .agent_profile_container {
      //   position: absolute;
      //   z-index: -1;
      //   width: 784px;
      //   height: 1140px;
      //   top: 18.5%;
      //   left: 34.2%;
      //   .agent_profile {
      //     object-fit: cover;
      //     width: 100%;
      //     height: 100%;
      //   }
      // }

      .agent_code {
        position: absolute;
        z-index: inherit;
        height: auto;
        top: 31.6%;
        left: 60.3%;
        color: #000000;
        font-family: 'Arimo_regular';
        font-size: 86px;
        display: flex;
        justify-content: center;
        text-align: center;
        font-weight: 400;
        &.prefix_name {
          top: 35%;
          left: 18.9%;
          font-size: 66px;
        }
      }

      .agent_name {
        position: absolute;
        z-index: inherit;
        display: flex;
        top: 27.7%;
        left: 21%;
        width: 150rem;
        height: 11rem;
        align-items: center;
        justify-content: center;
        color: #000000;
        font-family: 'Beautyful_comethrue_bold';
        font-weight: 600;
        font-size: 132px;
        &.mm_name {
          left: 20.5%;
          font-size: 115px;
          font-family: 'BMKing001-Bold';
        }
        &.more_ten {
          font-size: 120px;
        }
        &.more_eighteen {
          font-size: 96px;
        }
      }

      .bonus_award {
        position: absolute;
        z-index: inherit;
        top: 36.2%;
        left: 43.7%;
        font-size: 77px;
        width: 80rem;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'BMKing001-Bold';
        color: #ffffff;
        .award_amount {
          letter-spacing: 0.3rem;
        }
      }

      .bonus_product_list {
        position: absolute;
        z-index: inherit;
        top: 41.5%;
        left: 31.7%;
        display: flex;
        flex-direction: column;
        width: 1900px;
        height: 65rem;
        font-family: 'Ace';
        color: #000000;
        font-weight: 400;
        // row-gap: 9px;
        line-height: 85px;
        .product_info_row {
          left: 10%;
          width: 100%;
          display: grid;
          grid-template-columns: 45% 55%;
          .product_name {
            width: 100%;
            text-align: right;
          }
        }
      }
    }

    .bonus_more_ten_products {
      z-index: 25;
      // .agent_profile {
      //   position: absolute;
      //   z-index: -1;
      //   width: 714px;
      //   height: 1033px;
      //   top: 18.3%;
      //   left: 35.5%;
      // }
      // .agent_profile_container {
      //   position: absolute;
      //   z-index: -1;
      //   width: 714px;
      //   height: 1033px;
      //   top: 18.3%;
      //   left: 35.5%;
      //   .agent_profile {
      //     object-fit: cover;
      //     width: 100%;
      //     height: 100%;
      //   }
      // }

      .agent_code {
        position: absolute;
        z-index: inherit;
        // width: 180px;
        height: auto;
        top: 25.9%;
        left: 60.3%;
        color: #000000;
        font-family: 'Arimo_regular';
        font-size: 86px;
        display: flex;
        justify-content: center;
        text-align: center;
        font-weight: 400;
        &.prefix_name {
          top: 33.7%;
          left: 20%;
          font-size: 66px;
        }
      }

      .agent_name {
        position: absolute;
        z-index: inherit;
        display: flex;
        top: 22%;
        left: 20.5%;
        width: 150rem;
        height: 11rem;
        align-items: center;
        justify-content: center;
        color: #000000;
        font-family: 'Beautyful_comethrue_bold';
        font-weight: 400;
        font-size: 132px;
        &.mm_name {
          top: 22%;
          left: 20.5%;
          font-size: 90px;
          font-family: 'BMKing001-Bold';
        }
        &.more_ten {
          font-size: 120px;
        }
        &.more_eighteen {
          font-size: 96px;
        }
      }

      .bonus_award {
        position: absolute;
        z-index: inherit;
        top: 30.6%;
        left: 48.7%;
        font-size: 77px;
        width: 65rem;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'BMKing001-Bold';
        color: #ffffff;
        .award_amount {
          letter-spacing: 0.3rem;
        }
      }

      .bonus_product_list {
        position: absolute;
        z-index: inherit;
        top: 36%;
        left: 30.7%;
        display: flex;
        flex-direction: column;
        // row-gap: 2px;
        width: 1900px;
        height: 73rem;
        font-family: 'Ace';
        color: #000000;
        font-weight: 400;
        line-height: 67px;
        .product_info_row {
          left: 10%;
          width: 100%;
          display: grid;
          grid-template-columns: 45% 55%;
          .product_name {
            width: 100%;
            text-align: right;
          }
        }
        &.less_seventeen {
          line-height: 60px;
        }
        &.less_twenty {
          top: 59.5%;
          line-height: 53px;
        }
        &.more_twenty {
          top: 59.2%;
          line-height: 49px;
        }
      }
    }
  }

  .bonus_info_container_th {
    z-index: 25;
    .bonus_month {
      position: absolute;
      z-index: inherit;
      font-family: 'Arno_pro';
      font-size: 83px;
      top: 10.5%;
      left: 20%;
      color: #4e0000;
      font-weight: 400;
      letter-spacing: 2px;
      text-transform: uppercase;
      width: 1500px;
      height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .bonus_level {
      position: absolute;
      z-index: inherit;
      font-family: 'Arno_pro';
      font-size: 73px;
      text-transform: uppercase;
      color: #5a0000;
      letter-spacing: 1px;
      top: 12.5%;
      left: 20%;
      font-weight: 400;
      width: 1500px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .bonus_less_six_products {
      z-index: 25;
      .agent_profile {
        position: absolute;
        z-index: -1;
        width: 781px;
        height: 1058px;
        top: 17.2%;
        left: 34.3%;
      }

      .agent_code {
        position: absolute;
        z-index: inherit;
        width: 180px;
        height: auto;
        top: 36.8%;
        left: 18.2%;
        color: #dbb343;
        font-family: 'AbrilFatface_Regular';
        font-size: 41px;
        display: flex;
        justify-content: center;
        text-align: center;
        font-weight: 400;
        &.prefix_name {
          top: 34.4%;
          left: 18.3%;
          font-size: 72px;
        }
      }

      .agent_name {
        position: absolute;
        z-index: inherit;
        display: flex;
        top: 52.2%;
        left: 1.5%;
        width: 150rem;
        height: 11rem;
        align-items: center;
        justify-content: center;
        color: #871f24;
        font-family: 'AbrilFatface_Regular';
        font-weight: 400;
        font-size: 132px;
        &.mm_name {
          top: 52.7%;
          font-size: 100px;
        }
        &.more_twenty_three {
          top: 52.6%;
          font-size: 125px;
        }
      }

      .bonus_award {
        position: absolute;
        z-index: inherit;
        top: 58.9%;
        left: 30.6%;
        font-size: 85px;
        width: 60rem;
        height: 210px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'BMKING002_Regular';
        color: #ffffff;
        .award_amount {
          letter-spacing: 0.3rem;
        }
      }

      .bonus_product_list {
        position: absolute;
        z-index: inherit;
        top: 66%;
        left: 12.4%;
        display: flex;
        flex-direction: column;
        width: 1900px;
        height: 49rem;
        font-family: 'Ace';
        color: #5e1616;
        font-weight: 600;
        line-height: 75px;
        .product_info_row {
          left: 10%;
          width: 100%;
          display: grid;
          grid-template-columns: 45% 55%;
          .product_name {
            width: 100%;
            text-align: right;
          }
        }
      }
    }

    .bonus_more_six_products {
      z-index: 25;
      .agent_profile {
        position: absolute;
        z-index: -1;
        width: 709px;
        height: 1033px;
        top: 15.5%;
        left: 35.4%;
      }

      .agent_code {
        position: absolute;
        z-index: inherit;
        width: 180px;
        height: auto;
        top: 34.6%;
        left: 18.3%;
        color: #dcb444;
        font-family: 'AbrilFatface_Regular';
        font-size: 40px;
        display: flex;
        justify-content: center;
        text-align: center;
        font-weight: 400;
        &.prefix_name {
          top: 32.4%;
          left: 18.4%;
          font-size: 66px;
        }
      }

      .agent_name {
        position: absolute;
        z-index: inherit;
        display: flex;
        top: 46.4%;
        left: 1.5%;
        width: 150rem;
        height: 11rem;
        align-items: center;
        justify-content: center;
        color: #871f24;
        font-family: 'AbrilFatface_Regular';
        font-weight: 400;
        font-size: 132px;
        &.mm_name {
          top: 46.9%;
          font-size: 90px;
        }
        &.more_twenty_three {
          font-size: 125px;
        }
      }

      .bonus_award {
        position: absolute;
        z-index: inherit;
        top: 52%;
        left: 30.6%;
        font-size: 85px;
        width: 60rem;
        height: 210px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'BMKING002_Regular';
        color: #ffffff;
        .award_amount {
          letter-spacing: 0.3rem;
        }
      }

      .bonus_product_list {
        position: absolute;
        z-index: inherit;
        top: 59%;
        left: 12.5%;
        display: flex;
        flex-direction: column;
        width: 1900px;
        height: 68rem;
        font-family: 'Ace';
        color: #5e1616;
        font-weight: 600;
        line-height: 71px;
        .product_info_row {
          left: 10%;
          width: 100%;
          display: grid;
          grid-template-columns: 45% 55%;
          .product_name {
            width: 100%;
            text-align: right;
          }
        }
        &.less_seventeen {
          line-height: 60px;
        }
        &.less_twenty {
          top: 58.4%;
          line-height: 53px;
        }
        &.more_twenty {
          top: 58%;
          line-height: 49px;
        }
      }
    }
  }
}

/** trip all frame report card */
.six_months_trip_card_container {
  position: relative;
  width: 2480px;
  height: 3508px;

  .report_frame {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
  }

  .bonus_info_container {
    z-index: 25;

    .agent_profile_container {
      position: absolute;
      z-index: -1;
      width: 762px;
      height: 1143px;
      top: 9.0%;
      left: 62.9%;
      .agent_profile {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .agent_name {
      position: absolute;
      z-index: inherit;
      display: flex;
      top: 41%;
      left: 29.5%;
      width: 150rem;
      height: 11rem;
      align-items: center;
      justify-content: center;
      color: #000000;
      font-family: 'AbrilFatface_Regular';
      font-weight: 400;
      font-size: 120px;
      &.char-9 {
        font-size: 90px;
      }
      &.char-15 {
        font-size: 65px;
      }
      &.mm_name {
        font-size: 90px;
      }
    }
    .agent_code {
      position: absolute;
      z-index: inherit;
      // width: 180px;
      height: auto;
      top: 45.2%;
      left: 70.4%;
      color: #161616;
      font-family: 'AbrilFatface_Regular';
      font-size: 70px;
      display: flex;
      justify-content: center;
      text-align: center;
      font-weight: 400;
      &.prefix_name {
        top: 32.5%;
        left: 20.3%;
        font-size: 91px;
        font-weight: 700;
      }
    }

    .bonus_month {
      position: absolute;
      z-index: inherit;
      font-family: 'AbrilFatface_Regular';
      font-size: 83px;
      top: 11.2%;
      left: 34.3%;
      color: #025f92;
      font-weight: 400;
    }

    .bonus_award {
      position: absolute;
      z-index: inherit;
      top: 44.6%;
      left: 8.6%;
      font-size: 104px;
      width: 80rem;
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'bmking010_bold';
      color: #ffffff;
      .award_amount {
        letter-spacing: 0.3rem;
      }
    }

    .bonus_product_list {
      position: absolute;
      z-index: inherit;
      top: 53.9%;
      left: 7%;
      display: flex;
      flex-direction: column;
      width: 2200px;
      height: 65rem;
      font-size: 65px;
      font-family: 'Ace';
      color: #000000;
      line-height: 81px;
      .product_info_row {
        left: 10%;
        width: 100%;
        display: grid;
        grid-template-columns: 45% 55%;
        .product_name {
          width: 100%;
          text-align: right;
        }
      }
      &.more_twelve {
        font-size: 60px;
        line-height: 62px;
      }
    }

  }

  // .bonus_info_container_th {
  //   z-index: 25;
  //   .bonus_month {
  //     position: absolute;
  //     z-index: inherit;
  //     font-family: 'AbrilFatface_Regular';
  //     font-size: 83px;
  //     top: 10.5%;
  //     left: 33.9%;
  //     color: #00706f;
  //     font-weight: 400;
  //   }

  //   .agent_code {
  //     position: absolute;
  //     z-index: inherit;
  //     width: 180px;
  //     height: auto;
  //     top: 29.1%;
  //     left: 19.2%;
  //     color: #f8d955;
  //     font-family: 'AbrilFatface_Regular';
  //     font-size: 59px;
  //     display: flex;
  //     justify-content: center;
  //     text-align: center;
  //     font-weight: 400;
  //     &.prefix_name {
  //       top: 25.9%;
  //       left: 19.3%;
  //       font-size: 103px;
  //       font-weight: 400;
  //     }
  //   }

  //   .agent_profile_container {
  //     position: absolute;
  //     z-index: -1;
  //     width: 807px;
  //     height: 998px;
  //     top: 14.6%;
  //     left: 33.7%;
  //     .agent_profile {
  //       object-fit: cover;
  //       width: 100%;
  //       height: 100%;
  //     }
  //   }

  //   .bonus_less_six_products {
  //     z-index: 25;
  //     .agent_name {
  //       position: absolute;
  //       z-index: inherit;
  //       display: flex;
  //       top: 46.4%;
  //       left: 1.5%;
  //       width: 150rem;
  //       height: 11rem;
  //       align-items: center;
  //       justify-content: center;
  //       color: #007575;
  //       font-family: 'AbrilFatface_Regular';
  //       font-weight: 400;
  //       &.mm_name {
  //         top: 46.4%;
  //         font-size: 110px;
  //       }
  //     }

  //     .bonus_award {
  //       position: absolute;
  //       z-index: inherit;
  //       top: 53.7%;
  //       left: 22.4%;
  //       font-size: 90px;
  //       width: 85rem;
  //       height: 200px;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       font-family: 'BMKING002_Regular';
  //       color: #ffffff;
  //       .award_amount {
  //         letter-spacing: 0.3rem;
  //       }
  //     }

  //     .bonus_product_list {
  //       position: absolute;
  //       z-index: inherit;
  //       top: 61.5%;
  //       left: 8%;
  //       display: flex;
  //       flex-direction: column;
  //       width: 2200px;
  //       height: 65rem;
  //       font-family: 'Ace';
  //       color: #439087;
  //       line-height: 72px;
  //       .product_info_row {
  //         left: 10%;
  //         width: 100%;
  //         display: grid;
  //         grid-template-columns: 45% 55%;
  //         .product_name {
  //           width: 100%;
  //           text-align: right;
  //         }
  //       }
  //     }
  //   }

  //   .bonus_more_six_products {
  //     z-index: 25;
  //     .agent_name {
  //       position: absolute;
  //       z-index: inherit;
  //       display: flex;
  //       top: 45%;
  //       left: 1.5%;
  //       width: 150rem;
  //       height: 11rem;
  //       align-items: center;
  //       justify-content: center;
  //       color: #007575;
  //       font-family: 'AbrilFatface_Regular';
  //       font-weight: 400;
  //       &.mm_name {
  //         top: 45.1%;
  //         font-size: 110px;
  //       }
  //     }

  //     .bonus_award {
  //       position: absolute;
  //       z-index: inherit;
  //       top: 50.7%;
  //       left: 22.3%;
  //       font-size: 80px;
  //       width: 86rem;
  //       height: 200px;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       font-family: 'BMKING002_Regular';
  //       color: #ffffff;
  //       .award_amount {
  //         letter-spacing: 0.3rem;
  //       }
  //     }

  //     .bonus_product_list {
  //       position: absolute;
  //       z-index: inherit;
  //       top: 57.4%;
  //       left: 8%;
  //       display: flex;
  //       flex-direction: column;
  //       // row-gap: 2px;
  //       width: 2200px;
  //       height: 77rem;
  //       font-family: 'Ace';
  //       color: #439087;
  //       font-weight: 500;
  //       line-height: 70px;
  //       .product_info_row {
  //         left: 10%;
  //         width: 100%;
  //         display: grid;
  //         grid-template-columns: 45% 55%;
  //         .product_name {
  //           width: 100%;
  //           text-align: right;
  //         }
  //       }
  //     }
  //     &.more_fifteen_products {
  //       .bonus_product_list {
  //         top: 57.4%;
  //         left: 8%;
  //         line-height: 65px;
  //       }
  //     }
  //   }
  // }
}
