@font-face {
  font-family: 'AcademyEngavedLetPlain';
  src: url('../assets/fonts/AcademyEngravedLET.ttf');
}

@font-face {
  font-family: 'AvantGardeITCbyBT';
  src: url('../assets/fonts/AvantGardeITC-Demi.ttf');
}

@font-face {
  font-family: 'DreamscapeSans';
  src: url('../assets/fonts/DreamscapeSans.ttf');
}

@font-face {
  font-family: 'Georgia';
  src: url('../assets/fonts/Georgia.ttf');
}

@font-face {
  font-family: 'Rockwell';
  src: url('../assets/fonts/Rockwell.ttf');
}

@font-face {
  font-family: 'CourierNew';
  src: url('../assets/fonts/courbd.ttf');
}

/** April Bonus Frame Fonts */

@font-face {
  font-family: 'AvantGardeITCbyBT';
  src: url('../assets/fonts/april_frames/AvantGardeITCbyBT-Demi.otf');
}

@font-face {
  font-family: 'BMKing004';
  src: url('../assets/fonts/april_frames/BMKing004-Regular.ttf');
}

@font-face {
  font-family: 'BMKing001-Regular';
  src: url('../assets/fonts/april_frames/BMKing001-Regular.ttf');
}

@font-face {
  font-family: 'BMKing001-Light';
  src: url('../assets/fonts/april_frames/BMKing001-Light.ttf');
}

@font-face {
  font-family: 'BMKing001-Bold';
  src: url('../assets/fonts/april_frames/BMKing001-Bold.ttf');
}

@font-face {
  font-family: 'BRITANIC';
  src: url('../assets/fonts/april_frames/BRITANIC.TTF');
}

@font-face {
  font-family: 'News701BoldBt';
  src: url('../assets/fonts/april_frames/News_701_Bold_BT.ttf');
}

@font-face {
  font-family: 'NexaBold';
  src: url('../assets/fonts/april_frames/NexaBold.otf');
}

@font-face {
  font-family: 'NexaLight';
  src: url('../assets/fonts/april_frames/NexaLight.otf');
}

@font-face {
  font-family: 'RoughAnthemItalic';
  src: url('../assets/fonts/april_frames/RoughAnthemItalic.ttf');
}

@font-face {
  font-family: 'RoughAnthem';
  src: url('../assets/fonts/april_frames/RoughAnthem.ttf');
}

@font-face {
  font-family: 'Geogria';
  src: url('../assets/fonts/april_frames/georgia.ttf');
}

@font-face {
  font-family: 'Athene';
  src: url('../assets/fonts/april_frames/Athene.otf');
}

@font-face {
  font-family: 'Bahamas';
  src: url('../assets/fonts/april_frames/Bahamas.ttf');
}

@font-face {
  font-family: 'BahamasBold';
  src: url('../assets/fonts/april_frames/BahamasBold.ttf');
}

@font-face {
  font-family: 'Geogriab';
  src: url('../assets/fonts/april_frames/georgiab.ttf');
}

@font-face {
  font-family: 'Geogriai';
  src: url('../assets/fonts/april_frames/georgiai.ttf');
}

@font-face {
  font-family: 'Geogriaz';
  src: url('../assets/fonts/april_frames/georgiaz.ttf');
}

@font-face {
  font-family: 'Swiss911';
  src: url('../assets/fonts/april_frames/swiss911-extra-compr9c22f.ttf');
}

@font-face {
  font-family: 'fradm';
  src: url('../assets/fonts/april_frames/fradm.ttf');
}

@font-face {
  font-family: 'fradmcn';
  src: url('../assets/fonts/april_frames/fradmcn.ttf');
}

@font-face {
  font-family: 'RowdiesBold';
  src: url('../assets/fonts/april_frames/RowdiesBold.ttf');
}

@font-face {
  font-family: 'RowdiesLight';
  src: url('../assets/fonts/april_frames/RowdiesLight.ttf');
}

@font-face {
  font-family: 'RowdiesRegular';
  src: url('../assets/fonts/april_frames/RowdiesRegular.ttf');
}

@font-face {
  font-family: 'w01artHouse';
  src: url('../assets/fonts/april_frames/w01artHouse.ttf');
}

/** april product frame **/

@font-face {
  font-family: 'a_dot_space';
  src: url('../assets/fonts/april_product_frame/a_dot_space.otf');
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/april_product_frame/Avenir.ttf');
}

@font-face {
  font-family: 'Franxurter_Totally';
  src: url('../assets/fonts/april_product_frame/Franxurter_Totally.ttf');
}

@font-face {
  font-family: 'GeoSlab703_XBD_BT';
  src: url('../assets/fonts/april_product_frame/GeoSlab703_XBD_BT.ttf');
}

/** may product frame font */

@font-face {
  font-family: '018_YAMIN';
  src: url('../assets/fonts/fonts_for_may_frame/018\ YAMIN.ttf');
}

@font-face {
  font-family: 'AvantGardeITCbyBT-Demi';
  src: url('../assets/fonts/fonts_for_may_frame/AvantGardeITCbyBT-Demi.otf');
}

@font-face {
  font-family: 'Bebas-Regular';
  src: url('../assets/fonts/fonts_for_may_frame/Bebas-Regular.ttf');
}

@font-face {
  font-family: 'BMKing001-Bold';
  src: url('../assets/fonts//fonts_for_may_frame/BMKing001-Bold.ttf');
}

@font-face {
  font-family: 'BMKing001-Light';
  src: url('../assets/fonts/fonts_for_may_frame/BMKing001-Light.ttf');
}

@font-face {
  font-family: 'BMKing001-Regular';
  src: url('../assets/fonts/fonts_for_may_frame/BMKing001-Regular\ \(1\).ttf');
}

@font-face {
  font-family: 'SunnyspellsRegular-MV9ze';
  src: url('../assets/fonts/fonts_for_may_frame/SunnyspellsRegular-MV9ze.otf');
}

@font-face {
  font-family: 'times';
  src: url('../assets/fonts/fonts_for_may_frame/times.ttf');
}

@font-face {
  font-family: 'timesbd';
  src: url('../assets/fonts/fonts_for_may_frame/timesbd.ttf');
}

@font-face {
  font-family: 'timesbi';
  src: url('../assets/fonts/fonts_for_may_frame/timesbi.ttf');
}

@font-face {
  font-family: 'timesi';
  src: url('../assets/fonts/fonts_for_may_frame/timesi.ttf');
}

@font-face {
  font-family: 'U-Moe-FONT';
  src: url('../assets/fonts/fonts_for_may_frame/U-MOE-FONT.ttf');
}

@font-face {
  font-family: 'BrandBunR';
  src: url('../assets/fonts/BradBunR.ttf');
}

/* june all frame fonts */
@font-face {
  font-family: 'Branch';
  src: url('../assets/fonts/fonts_for_june_all_frames/Branch.otf');
}

@font-face {
  font-family: 'comic';
  src: url('../assets/fonts/fonts_for_june_all_frames/comic.ttf');
}

@font-face {
  font-family: 'comicbd';
  src: url('../assets/fonts/fonts_for_june_all_frames/comicbd.ttf');
}

@font-face {
  font-family: 'comici';
  src: url('../assets/fonts/fonts_for_june_all_frames/comici.ttf');
}

@font-face {
  font-family: 'comicz';
  src: url('../assets/fonts/fonts_for_june_all_frames/comicz.ttf');
}

@font-face {
  font-family: 'DynaPuff';
  src: url('../assets/fonts/fonts_for_june_all_frames/DynaPuff-VariableFont_wdth\,wght.ttf');
}

@font-face {
  font-family: 'FontsFreeNetBranch';
  src: url('../assets/fonts/fonts_for_june_all_frames/FontsFree-Net-Branch.ttf');
}

@font-face {
  font-family: 'FontsFreeNetMontserratBoldltalic';
  src: url('../assets/fonts/fonts_for_june_all_frames/FontsFree-Net-Montserrat-BoldItalic.ttf');
}

@font-face {
  font-family: 'MontserratItalicVariableFont';
  src: url('../assets/fonts/fonts_for_june_all_frames/Montserrat-Italic-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'MontserratVariableFont';
  src: url('../assets/fonts/fonts_for_june_all_frames/Montserrat-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'ThitSar-Bold';
  src: url('../assets/fonts/fonts_for_june_all_frames/OT01_ThitSar-Bold.ttf');
}

@font-face {
  font-family: 'ThitSar-Regular';
  src: url('../assets/fonts/fonts_for_june_all_frames/OT01_ThitSar-Regular.ttf');
}

@font-face {
  font-family: 'ThitSar-Thin';
  src: url('../assets/fonts/fonts_for_june_all_frames/OT01_ThitSar-Thin.ttf');
}

/* july all frame certificate */
@font-face {
  font-family: 'LHANDW';
  src: url('../assets/fonts/fonts_for_july_all_frames/LHANDW.TTF');
}

@font-face {
  font-family: 'soupofjustice';
  src: url('../assets/fonts/fonts_for_july_all_frames/soupofjustice.ttf');
}

/* august all frame certificate */
@font-face {
  font-family: 'FONTTSFREE-NET-BRANCH';
  src: url('../assets/fonts/fonts_for_august_all_frames/FONTSFREE-NET-BRANCH.ttf');
}

@font-face {
  font-family: 'SM04_MOON-BOLD';
  src: url('../assets/fonts/fonts_for_august_all_frames/SM04_MOON-BOLD.ttf');
}

@font-face {
  font-family: 'SM04_MOON-REGULAR';
  src: url('../assets/fonts/fonts_for_august_all_frames/SM04_MOON-REGULAR.ttf');
}

@font-face {
  font-family: 'SM04_MOON-THIN';
  src: url('../assets/fonts/fonts_for_august_all_frames/SM04_MOON-THIN.ttf');
}

/* 2025 january all frame fonts */

@font-face {
  font-family: 'Arimo-Regular';
  src: url('../assets/fonts/2025_january_all_frames_fonts/Arimo-Regular.ttf');
}

@font-face {
  font-family: 'Ace';
  src: url('../assets/fonts/2025_january_all_frames_fonts/Ace.ttf');
}

@font-face {
  font-family: 'Berlin-Sans-Fb';
  src: url('../assets/fonts/2025_january_all_frames_fonts/brlnsr.ttf');
}

/* three months trip fonts */

@font-face {
  font-family: '057_YAMIN';
  src: url('../assets/fonts/trip_frames/057_YAMIN.ttf');
}

@font-face {
  font-family: 'Montserrat_Italic_VariableFont_wght';
  src: url('../assets/fonts/trip_frames/Montserrat-Italic-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Montserrat_Regular';
  src: url('../assets/fonts/trip_frames/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat_VariableFont_wght';
  src: url('../assets/fonts/trip_frames/Montserrat-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'BUBBLEGUM';
  src: url('../assets/fonts/trip_frames/BUBBLEGUM.ttf');
}

@font-face {
  font-family: 'Cormorant-Italic';
  src: url('../assets/fonts/trip_frames/Cormorant-Italic-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Cormorant-VariableFont';
  src: url('../assets/fonts/trip_frames/Cormorant-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Cormorant-Regular';
  src: url('../assets/fonts/trip_frames/Cormorant-Regular.ttf');
}

@font-face {
  font-family: 'Nexa-ExtraLight';
  src: url('../assets/fonts/trip_frames/NEXA-EXTRALIGHT.ttf');
}

@font-face {
  font-family: 'Nexa-Heavy';
  src: url('../assets/fonts/trip_frames/NEXA-HEAVY.ttf');
}

@font-face {
  font-family: 'w35art-house';
  src: url('../assets/fonts/trip_frames/w35art-house.ttf');
}

@font-face {
  font-family: 'Bauhausm';
  src: url('../assets/fonts/trip_frames/BAUHAUSM.ttf');
}

@font-face {
  font-family: 'Anton-Regular';
  src: url('../assets/fonts/trip_frames/Anton-Regular.ttf');
}

@font-face {
  font-family: 'Rubik-Italic';
  src: url('../assets/fonts/trip_frames/Rubik-Italic-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/trip_frames/Rubik-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'W15Art-House';
  src: url('../assets/fonts/trip_frames/W15Art-House.TTF');
}

/* september all frame certificate */
@font-face {
  font-family: 'Berlin_sans';
  src: url('../assets/fonts/fonts_for_september_all_frames/BRLNSDB.TTF');
}
@font-face {
  font-family: 'Bodoni';
  src: url('../assets/fonts/fonts_for_september_all_frames/BOD.TTF');
}

@font-face {
  font-family: 'w35art_house';
  src: url('../assets/fonts/trip_frames/w35art-house.ttf');
}

@font-face {
  font-family: 'Montserrat_thin';
  src: url('../assets/fonts/fonts_for_september_all_frames/MONTSERRAT-THIN.OTF');
}

/* september_proudct_frame */
@font-face {
  font-family: 'Acumin_concept';
  src: url('../assets/fonts/september_product_fonts/Acumin-Variable-Concept.ttf');
}

@font-face {
  font-family: 'Allenoire_Free_Personal';
  src: url('../assets/fonts/september_product_fonts/FontsFree-Net-Allenoire-Font.ttf');
}

@font-face {
  font-family: 'Kanit';
  src: url('../assets/fonts/september_product_fonts/KANIT-BOLD.ttf');
}

@font-face {
  font-family: 'BMKing009_Bold';
  src: url('../assets/fonts/fonts_for_september_all_frames/BMKing009-Bold.ttf');
}

// october all frame font
@font-face {
  font-family: 'galada';
  src: url('../assets/fonts/fonts_for_october_all_frames/GALADA-REGULAR.ttf');
}

@font-face {
  font-family: 'bmking010_bold';
  src: url('../assets/fonts/fonts_for_october_all_frames/BMKING010-BOLD.TTF');
}

@font-face {
  font-family: 'fradmit';
  src: url('../assets/fonts/fonts_for_october_all_frames/FRADMIT.TTF');
}

// november all frames fonts
@font-face {
  font-family: 'masterpiece_voval';
  src: url('../assets/fonts/fonts_for_november_all_frames/MVO_.TTF');
}

@font-face {
  font-family: 'kanit_regular';
  src: url('../assets//fonts/fonts_for_november_all_frames/KANIT-REGULAR.TTF');
}

@font-face {
  font-family: 'BMKING002_Regular';
  src: url('../assets/fonts/fonts_for_november_all_frames/BMKING002-REGULAR.ttf');
}

@font-face {
  font-family: 'Academy_Engraved_LET_Plain';
  src: url('../assets/fonts/fonts_for_november_all_frames/AcademyEngravedLetPlain.woff');
}

@font-face {
  font-family: 'Arial_Rounded_Bold';
  src: url('../assets/fonts/fonts_for_november_all_frames/Arial Rounded Bold.ttf');
}

@font-face {
  font-family: 'BebasNeue_Bold';
  src: url('../assets/fonts/fonts_for_november_all_frames/BebasNeue Bold.ttf');
}

@font-face {
  font-family: 'W49ArtHouse_MAC';
  src: url('../assets/fonts/fonts_for_november_all_frames/W49ArtHouse MAC.ttf');
}

@font-face {
  font-family: 'AVANTE';
  src: url('../assets/fonts/fonts_for_november_all_frames/avante.ttf');
}

@font-face {
  font-family: 'Junegull_Regular';
  src: url('../assets/fonts/fonts_for_november_all_frames/junegull_rg.ttf');
}

//november three months trip fonts
@font-face {
  font-family: 'Bask_old_face';
  src: url('../assets/fonts/trip_frames/BASKVILL.ttf');
}

// font for december all frames
@font-face {
  font-family: 'Ace';
  src: url('../assets/fonts/fonts_for_december_all_frames/Ace.ttf');
  src: url('../assets/fonts/fonts_for_december_all_frames/Ace.ttf'); /* IE9 Compat Modes */
  src: url('../assets/fonts/fonts_for_december_all_frames/Ace.ttf') format('embedded-opentype'),
    url('../assets/fonts/fonts_for_december_all_frames/Ace.ttf') format('truetype');
}

@font-face {
  font-family: 'AbrilFatface_regular';
  src: url('../assets/fonts/fonts_for_december_all_frames/AbrilFatface-Regular.otf');
}

@font-face {
  font-family: 'F53N';
  src: url('../assets/fonts/fonts_for_december_all_frames/F53N.TTF');
}

//font for december product frame
@font-face {
  font-family: 'Aleo_Medium';
  src: url('../assets/fonts/december_product_frames/Aleo-Medium.ttf');
}

@font-face {
  font-family: 'BerkshireSwash-Regular';
  src: url('../assets/fonts/december_product_frames/BerkshireSwash-Regular.ttf');
}

@font-face {
  font-family: 'SM04_Moon_Bold';
  src: url('../assets/fonts/december_product_frames/SM04_Moon-Bold.ttf');
}

@font-face {
  font-family: 'SM04_Moon_Regular';
  src: url('../assets/fonts/december_product_frames/SM04_Moon_Regular.ttf');
  src: url('../assets/fonts/december_product_frames/SM04_Moon_Regular.ttf'); /* IE9 Compat Modes */
  src: url('../assets/fonts/december_product_frames/SM04_Moon_Regular.ttf?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/december_product_frames/SM04_Moon_Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Voire_Display_Mdedium';
  src: url('../assets/fonts/december_product_frames/Voire_Display_Medium.ttf');
}

// font for january product frames
@font-face {
  font-family: 'poppins_regular';
  src: url('../assets/fonts/january_product_frames/Poppins-Regular.otf');
}

@font-face {
  font-family: 'Poppins_bold';
  src: url('../assets/fonts/Poppins-Bold.otf');
}

@font-face {
  font-family: 'rosamby_regular';
  src: url('../assets/fonts/january_product_frames/rosamby-rosamby-regular-400.otf');
}

@font-face {
  font-family: 'SM01_watokelay_regular';
  src: url('../assets/fonts/january_product_frames/SM01_WaTokeLay-Regular.ttf');
}

// fonts for sep to feb 2024 highest bonus
@font-face {
  font-family: 'Arno_pro';
  src: url('../assets/fonts/ArnoPro-Regular.otf');
}

// font for march 2024 all frames
@font-face {
  font-family: 'Masterpiece_VOval';
  src: url('../assets/fonts/fonts_for_march_all_frames/MEGVOV-1.TTF');
}

@font-face {
  font-family: 'Cent_Roman_Regular';
  src: url('../assets/fonts/Cent_Roman_Regular.TTF');
}

@font-face {
  font-family: 'Cent_Roman_Bold';
  src: url('../assets/fonts/Cent_Roman_Bold.TTF');
}

@font-face {
  font-family: 'Optima_Roman';
  src: url('../assets/fonts/OptimaRoman.ttf');
}

@font-face {
  font-family: 'OptimaLTPro_Medium';
  src: url('../assets/fonts/OptimaLTPro-Medium.ttf');
}

@font-face {
  font-family: 'Gilmoray';
  src: url('../assets/fonts/trip_frames/gilmoray.otf');
}

@font-face {
  font-family: 'Roboto_Itelic';
  src: url('../assets/fonts/trip_frames/Roboto-Italic.ttf');
}

@font-face {
  font-family: 'Roboto_Bold';
  src: url('../assets/fonts/trip_frames/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Itim_Regular';
  src: url('../assets/fonts/trip_frames/Itim-Regular.otf');
}

@font-face {
  font-family: 'Dutch_Regular';
  src: url('../assets/fonts/dutch.ttf');
}

@font-face {
  font-family: 'Beauty_Bright';
  src: url('../assets/fonts/trip_frames/BEAUTY-BRIGHT-PERSONAL-USE.TTF');
}

@font-face {
  font-family: 'Bebaskai';
  src: url('../assets/fonts/trip_frames/BEBASKAI.OTF');
}

@font-face {
  font-family: 'Ranchers_regular';
  src: url('../assets/fonts/RANCHERS-REGULAR.TTF');
}

@font-face {
  font-family: 'Poetsenone_regular';
  src: url('../assets/fonts/POETSENONE-REGULAR.TTF');
}

@font-face {
  font-family: 'Beautyful_comethrue_bold';
  src: url('../assets/fonts/font_for_six_month/BeautifulComethrueBold-3lOxz-BF65c1ac4cb15cf.otf');
}

@font-face {
  font-family: 'Arimo_regular';
  src: url('../assets/fonts/font_for_six_month/Arimo-Regular.ttf');
}

@font-face {
  font-family: 'Berlin_Sans_Regular';
  src: url('../assets/fonts/font_for_six_month/brlnsr.ttf');
}

@font-face {
  font-family: 'BMking-007';
  src: url('../assets/fonts/BMKing007-Regular.ttf')
}

@font-face {
  font-family: 'AsturoGrandSerif';
  src: url('../assets/fonts/AsturoGrandSerifDEMO-Regular-BF66d5f201b618b.otf')
}

body {
  font-family: Roboto, sans-serif;
  font-weight: initial;
}

tr {
  color: inherit !important;
  display: table-row !important;
  vertical-align: middle !important;
  outline: 0px !important;
}
th {
  font-size: 0.875rem !important;
  color: rgb(33, 33, 33) !important;
  font-weight: 500 !important;
}
td {
  font-weight: 400 !important;
  color: rgb(97, 97, 97) !important;
  font-size: 0.875rem !important;
}

td > a {
  color: #877afa !important;
}

label {
  font-size: 0.875rem !important;
}

input {
  font-size: 0.9rem !important;
}

.table-width {
  width: 1300px;
  overflow-x: scroll;
}

.table-width.overall {
  width: 1700px;
}

.table-width.year-end {
  width: 1750px;
}
.font-italic {
  font-style: italic;
  font-weight: bold;
  font-family: 'Public Sans', sans-serif;
}

.nav-item > .nav-link {
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto;
  font-size: 0.875rem !important;
  font-weight: 400;
}

.main {
  margin-bottom: 1rem;
}

.footer {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: #516a7c !important;
}

.pr-1 {
  padding-right: 1px !important;
}

.pr-35 {
  padding-right: 35px !important;
}
.pl-0 {
  padding-left: 0 !important;
}

.pl-9 {
  padding-left: 9px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.horizonal-scroll {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.dropdown-item {
  padding: 14px 30px !important;
  color: rgb(97, 97, 97);
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
}

.dropdown-item:hover {
  background-color: rgb(237, 231, 246);
  color: rgb(94, 53, 177);
}

.card_breadcrumb {
  margin-bottom: 1rem;
}

.card_breadcrumb__body {
  margin-bottom: -27px;
}

.card_breadcrumb__title {
  flex: 1 1 auto;
  font-size: 1.25rem !important;
  color: rgb(33, 33, 33) !important;
  font-family: Roboto, sans-serif;
  font-weight: 500;
}

.card_breadcrumb > ul {
  list-style: none;
}

.breadcrumb-item > a {
  text-decoration: none;
  color: rgb(33, 33, 33);
  font-size: 0.875rem;
}

.breadcrumb-item.active {
  margin-top: 3px;
  font-size: 0.875rem !important;
}

.card {
  border-radius: 0.6rem !important;
}

.card_breadcrumb__container {
  color: rgb(97, 97, 97);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 24px;
  border: 1px solid rgba(144, 202, 249, 0.46);
  background: rgb(255, 255, 255);
}

.card_container {
  display: flex;
  justify-content: flex-end;
}

.card_container__button,
.card_container__button:hover,
.card_container__button:focus,
.card_container__button:active {
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  box-shadow: rgb(0 171 85 / 24%) 0px 8px 16px 0px !important;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 171, 85);
  outline: 0px;
  border: 0px;
  margin: 0px;
  padding: 7px 16px;
}

.card_container__button_excel,
.card_container__button_excel:hover,
.card_container__button_excel:focus,
.card_container__button_excel:focus-visible,
.card_container__button_excel:active {
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  color: rgb(0, 171, 85);
  background-color: rgb(255, 255, 255);
  border: 2px;
  margin-right: 10px;
  padding: 6px 16px;
  box-shadow: none;
}

.agent_register__button,
.agent_register__button:hover,
.agent_register__button:focus,
.agent_register__button:active {
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  box-shadow: rgba(176, 198, 255, 0.24) 0px 8px 16px 0px !important;
  color: #2196f3;
  background-color: #e3f2fd;
  outline: 0px;
  border: 0px;
  margin: 0px;
  padding: 5px 10px;
}

.agent_approve__button,
.agent_approve__button:hover,
.agent_approve__button:focus,
.agent_approve__button:active {
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  box-shadow: rgba(204, 255, 176, 0.24) 0px 8px 16px 0px !important;
  color: #00c853;
  background-color: rgba(185, 246, 202, 0.376);
  outline: 0px;
  border: 0px;
  margin: 0px;
  padding: 5px 10px;
}

.modal-title {
  font-size: 1.18rem !important;
}

.modal-body {
  font-size: 0.92rem !important;
}

.modal-footer > .btn {
  font-size: 0.91rem !important;
  font-weight: 500 !important;
}

.modal-footer > .btn-danger,
.btn-success {
  color: #fff !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mr-10 {
  margin-right: 10px;
}

.ml-3 {
  margin-left: 3px;
}

.ml-9 {
  margin-left: 9px;
}

.ml-14 {
  margin-left: 14px;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-25 {
  margin-left: 25px;
}

.ml-29 {
  margin-left: 29px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-37 {
  margin-left: 37px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-44 {
  margin-left: 44px;
}

.ml-66 {
  margin-left: 66px;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.custom_btn {
  height: 22px;
  min-width: 22px;
  line-height: 0;
  border-radius: 6px;
  border: none;
  cursor: default;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 0px 8px;
  font-size: 0.75rem;
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.custom_btn_success {
  color: rgb(34, 154, 22);
  background-color: rgba(84, 214, 44, 0.16);
}

.custom_btn_danger {
  color: rgb(183, 33, 54);
  background-color: rgba(255, 72, 66, 0.16);
}

.btn_search,
.btn_search:hover,
.btn_search:focus,
.btn_search:active {
  margin-right: 7px;
  margin-top: 30px !important;
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  box-shadow: rgb(33 150 243 / 30%) 0px 12px 14px 0px !important;
  color: rgb(255, 255, 255);
  background-color: rgb(33, 150, 243);
  outline: 0px;
  border: 0px;
  margin: 0px;
  padding: 7px 16px;
}

.btn_primary,
.btn_primary:hover,
.btn_primary:focus,
.btn_primary:active {
  margin-right: 7px;
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  box-shadow: rgb(33 150 243 / 30%) 0px 12px 14px 0px !important;
  color: rgb(255, 255, 255);
  background-color: #321fdb;
  outline: 0px;
  border: 0px;
  margin: 0px;
  padding: 7px 16px;
}

.btn_search:disabled {
  background-color: #cccccc !important;
  color: #666666 !important;
}

.btn_search__icon {
  margin-right: 5px;
}

.btn_export,
.btn_export:hover,
.btn_export:focus,
.btn_search:active {
  margin-right: 7px;
  margin-top: 30px !important;
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  box-shadow: rgb(0 171 85 / 24%) 0px 8px 16px 0px !important;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 171, 85);
  outline: 0px;
  border: 0px;
  margin: 0px;
  padding: 7px 16px;
}

.btn_success,
.btn_success:hover,
.btn_success:focus,
.btn_success:active,
.btn:disabled {
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  box-shadow: rgb(0 171 85 / 24%) 0px 8px 16px 0px !important;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 171, 85);
  outline: 0px;
  border: 0px;
  margin: 0px;
  padding: 7px 16px;
  margin-right: 7px;
}

.btn_back {
  margin-left: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-transform: capitalize;
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  line-height: 1.75;
  min-width: 64px;
  padding: 7px 16px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 700;
  border-radius: 4px;
  color: rgb(244, 67, 54);
}

.btn_back:hover,
.btn_back:focus,
.btn_back:active {
  color: white;
  text-decoration: none;
  background-color: #ff4747;
  box-shadow: rgb(244 67 54 / 24%) 0px 8px 16px 0px !important;
}

.btn-danger {
  color: #000;
  background-color: #ff4747;
  border-color: #ff4747;
}

.back {
  text-decoration: none;
}

.back_home {
  margin-top: 4rem;
}

.back_home > button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 600;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji';
  font-size: 0.875rem;
  line-height: 1.75;
  min-width: 64px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(80, 72, 229, 0.5);
  color: rgb(80, 72, 229);
  box-shadow: none;
  text-transform: none;
  padding: 8px 20px;
}

.back_home > button:hover {
  text-decoration: none;
  background-color: #d9ecff;
  border: 1px solid rgb(80, 72, 229);
}

.MuiBox-root .css-19kzrtu {
  padding: 5px 0px !important;
}

.css-b62m3t-container {
  font-size: 14px !important;
}

.table td {
  padding: 1.17rem 0;
}

.form-select {
  font-size: 0.9rem !important;
}

.modal .modal-dialog .modal-content .modal-header {
  padding: 25px 26px !important;
}

.modal .modal-dialog .modal-content .modal-body {
  padding: 35px 26px !important;
}

.modal .modal-dialog .modal-content .modal-footer {
  padding: 15px 31px !important;
}

.available {
  font-size: 12px !important;
}

.month {
  font-size: 12px !important;
  font-weight: bold !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  color: #fff !important;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  font-size: 12px !important;
}

.monthlyPromotion_table__td {
  width: 95px;
  padding: 0px 15px 0px 0px !important;
}

.transaction_table__td {
  width: 95px;
  padding: 0px 10px 0px 0px !important;
}

.transaction_table__td__number {
  padding: 1.17rem 1rem 1.17rem 0rem !important;
}

.currency_table__td {
  padding: 1.17rem 1.45rem !important;
}

.currency_table__td_td {
  padding: 30px 50px !important;
}

.product_table__td {
  padding: 1.17rem 2.45rem !important;
}

.product_status {
  font-family: Roboto, sans-serif;
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 16px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: default;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  padding: 0px 3px;
  vertical-align: middle;
  box-sizing: border-box;
}

.product_status > span {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  padding-right: 8px;
  white-space: nowrap;
}
.transaction {
  font-family: Roboto, sans-serif;
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 10px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: default;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  padding: 0px;
  vertical-align: middle;
  box-sizing: border-box;
}

.transaction > span {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  padding-right: 8px;
  white-space: nowrap;
}

.transaction_delete {
  height: 30px;
  min-width: 24px;
  line-height: 0;
  border-radius: 6px;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  text-transform: capitalize;
  padding: 0px 12px;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #ff4747;
  border: none;
}

.label-checkbox {
  font-size: 0.875rem !important;
}

.bonus_level_status {
  font-family: Roboto, sans-serif;
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 16px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: default;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  padding: 0px;
  vertical-align: middle;
  box-sizing: border-box;
}

.bonus_level_status > span {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  padding-right: 8px;
  white-space: nowrap;
}

.agent_status {
  font-family: Roboto, sans-serif;
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 16px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: default;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  padding: 0px;
  vertical-align: middle;
  box-sizing: border-box;
}

.agent_status > span {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  padding-right: 8px;
  white-space: nowrap;
}

.agent_pending {
  color: #ffffff;
  background-color: #c6c571;
}

.agent_register {
  // color: rgb(33, 150, 243);
  // background-color: rgb(227, 242, 253);
  color: #fff;
  font-weight: 500;
  background-image: linear-gradient(310deg, #2196f3, #b2d4ef);
}

.agent_active,
.stock_increase {
  // color: rgb(0, 200, 83);
  // background-color: rgba(185, 246, 202, 0.376);
  color: #fff;
  font-weight: 500;
  background-image: linear-gradient(310deg, #17ad37, #98ec2d);
}

.agent_inactive {
  // background-color: #f688b6;
  color: #fff;
  font-weight: 500;
  background-image: linear-gradient(310deg, #627594, #a8b8d8);
}

.agent_deactivate,
.stock_decrease {
  color: #be2516;
  background-color: #ff8e99;
}

.agent_delete {
  color: #be2516;
  background-color: #ff8e99;
}

.agent_skip {
  color: #fff;
  font-weight: 500;
  background-image: linear-gradient(310deg, #16beb0f6, #3ed4c7f6);
}

.transaction {
  text-decoration: none;
  height: 22px;
  min-width: 22px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 15px;
  font-size: 0.75rem;
  font-weight: bold;
  font-family: 'Public Sans', sans-serif;
}

.fb_transaction {
  color: gray;
  background-color: rgba(84, 214, 44, 0.16);
}

.smart_sale_transaction {
  color: rgb(34, 154, 22);
  background-color: rgba(84, 214, 44, 0.16);
}

.agent_avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-family: Roboto, sans-serif;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  color: rgb(30, 136, 229);
  background: rgb(144, 202, 249);
}

.agent_avatar > img {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px;
}

.image_path {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: Roboto, sans-serif;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 4px;
  overflow: hidden;
  user-select: none;
  color: rgb(30, 136, 229);
  background: rgb(144, 202, 249);
  width: 60px;
  height: 60px;
  margin: auto;
}

.image_path > img {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px;
}

button[disabled] {
  pointer-events: auto;
}

.login_container {
  background-color: rgb(227, 242, 253);
}

.lottie-inline {
  display: inline-block;
  margin: 0 auto;
}

.react-toast-notifications__container {
  top: 60px !important;
}

.select_form_control {
  font-size: 0.89rem !important;
}

.fa-heart {
  animation: fa-heart 5s ease infinite;
}
@keyframes fa-heart {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.25);
  }
  20% {
    transform: scale(1);
  }
  30% {
    transform: scale(1);
  }
  35% {
    transform: scale(1.25);
  }
  50% {
    transform: scale(1);
  }
  55% {
    transform: scale(1.25);
  }
  70% {
    transform: scale(1);
  }
}

.view_all {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-transform: capitalize;
  font-family: Roboto, sans-serif;
  font-size: 0.8125rem;
  line-height: 1.75;
  min-width: 64px;
  padding: 4px 5px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  box-shadow: none;
  font-weight: 500;
  border-radius: 4px;
}

.view_all > a {
  color: rgb(33, 150, 243) !important;
}

.generate_status_minimize {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 70px;
}

.generate_progress_bar_container {
  // width: calc(100% - 200px);
  width: 100%;
}
.generate_progress_text {
  font-size: 14px;
  color: #516a7c;
}

.animation_popup_overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.animation_popup_container {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.animation_popup {
  width: 500px;
  height: auto;
  background-color: #18273f;
  border-radius: 45px;
  padding: 20px;
  position: fixed;
  z-index: 2000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .close_popup {
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    background-color: transparent;
    width: 25px;
    height: 25px;
    cursor: pointer;
    padding: 0;
  }

  .animation_popup_body {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: clamp(50%, 430px, 90%);
    margin: auto;
    .animation_container {
      width: 180px;
      height: 180px;
      display: grid;
      place-items: center;
    }
    .animation_popup_title {
      margin: 20px 0;
      font-size: 20px;
      font-family: Roboto, sans-serif;
    }
  }
}

.download_certificate_popup {
  width: 100%;
  .progress_bar {
    width: 80%;
    margin: 6px auto;
  }
  p {
    margin: 20px auto;
  }
  .progress_count {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin: 6px auto;
  }
  .button_container {
    width: 60%;
    display: flex;
    justify-content: space-between;
    margin: 18px auto;
    &.single_btn_container {
      justify-content: center;
    }
    button {
      width: 100px;
      height: 38px;
      border: none;
      border-radius: 25px;
      color: white;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: 600;
      &:hover {
        box-shadow: rgba(51, 83, 112, 0.3) 0px 5px 8px 0px !important;
      }
      &:disabled {
        background-color: #cdcdcd;
      }
    }
    .cancel_btn {
      background-color: #1b3152;
    }
    .submit_btn {
      background-color: #328af1;
    }
  }
  .download_certificate_status {
    height: 35px;
    font-size: 17px;
    font-weight: 400;
  }

  .progress_bar_loading {
    background-color: #d1d5db;
    border-radius: 9999px;
    width: 100%;
    height: 0.75rem;
    position: relative;
    overflow: hidden;
    .progress_bar__progress {
      background-color: #3b82f6;
      border-radius: 9999px;
      position: absolute;
      bottom: 0;
      top: 0;
      width: 50%;

      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-name: indeterminate;
    }
  }

  @keyframes indeterminate {
    from {
      left: -50%;
    }
    to {
      left: 100%;
    }
  }
}

/** November All Frame CSS */
.generate-certificate-container {
  z-index: 1500;
  top: 0;
  color: white;
}

.report-card-list-container {
  // width: 900px;
  position: absolute;
  display: flex;
  flex-wrap: nowrap !important;
  column-gap: 35px;
  overflow-x: scroll;
  // background-color: pink;
  top: -4500px;
  // z-index: 50000;
}

.frame_preview_popup {
  height: auto;
  width: 500px;
  background-color: #18273f;
  position: fixed;
  z-index: 2000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 20px;
  border-radius: 10px;
  .generate_frame_button_container {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .cancel-btn {
      background-color: #1b3152;
    }
    .submit-btn {
      background-color: #328af1;
    }
  }
}

.nav_img_icon {
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  // background-color: white;
  margin-left: 8px;
  margin-right: 20px;
  // fill: rgba(255, 255, 255, 0.6);
  color: rgba(255, 255, 255, 0.6);
}

.filter_loading_container {
  width: 100%;
  height: 150px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  background-color: #f5f5f5;
  color: #696969;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // position: relative;
  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
  }
  .loader {
    color: #000;
    font-size: 7px;
    position: absolute;
    text-indent: -9999em;
    // transform: translateZ(0);
    transform: translate(-50%, -60%);
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3.5em;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3.5em;
  }

  @keyframes bblFadInOut {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
}

.none_text_decoration {
  text-decoration: none !important;
  color: #000 !important;
}

.sticky_column {
  position: sticky;
  background-color: #fff !important;
  right: 0;
  z-index: 100;
}
.sticky_column::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #cacaca;
  left: 0;
  top: 0;
  // box-shadow: -6px -6px 9px #c7c7c7,
  //            6px 6px 9px #f9f9f9;
}

.active_sticky {
  position: sticky;
  z-index: 100;
  width: 60px;
  right: 0;
  background-color: #fff !important;
}

.status_sticky {
  position: sticky;
  z-index: 100;
  width: 135px;
  right: 64px;
  background-color: #fff !important;
}
.status_sticky::before,
.active_sticky::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #cacaca;
  left: 0;
  top: 0;
}

.active-td {
  width: 50px;
  // padding: 5px;
}
.sale-promotion-td {
  width: 17rem;
}

.sale-promotion-input {
  width: 15rem;
}

@media (max-width: 700px) {
  .sale-promotion-input {
    width: 11rem;
  }
}

.survey_question_container {
  margin: 1rem 0;
}

.survey_question_footer {
  display: flex;
  row-gap: 4px;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem 2rem;
  align-items: center;
  .question_action {
    all: unset;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-inline: 4px;
    &:hover {
      background-color: #ececec;
    }
    &.new_question {
      color: rgb(1 126 53);
    }
    &.delete_question {
      color: #be2516;
      &:disabled {
        color: #cdcdcd;
      }
    }
  }
  .survey_question_required {
    margin-left: 10px;
    border-left: 1px solid #cdcdcd;
    display: flex;
    row-gap: 4px;
    font-size: 1rem;
    align-items: center;
  }
}

.survey_answer_list {
  list-style-type: none;
  padding-left: 1rem;
  width: 100%;
  li {
    display: flex;
    height: 2.5rem;
  }
  .survey_list_type {
    display: flex;
    row-gap: 4px;
    align-items: center;
    width: 100%;
    .radio {
      width: 18px;
      height: 18px;
      border: 2px solid #cdcdcd;
      border-radius: 50%;
    }
    .checkbox {
      width: 18px;
      height: 18px;
      border: 2px solid #cdcdcd;
      border-radius: 5px;
    }
  }
  .prepare_answer_textbox {
    border: none;
    outline: none;
    width: 100%;
    border-bottom: 1px solid #cdcdcd;
    border-radius: 0;
    box-shadow: none;
    &:hover,
    &:focus {
      outline: none !important;
      border-bottom: 2px solid #287be4;
    }
    &:focus-visible {
      border-bottom: 2px solid #287be4;
    }
  }
  .delete_option {
    all: unset;
    cursor: pointer;
    margin-inline: -1rem;
  }
  .new_option_btn {
    all: unset;
    font-size: 0.9rem;
    margin-inline-start: 0.8rem;
    height: 2.5rem;
    cursor: pointer;
  }
}

.survey_date_time {
  position: relative;
  .calendar_icon {
    position: absolute;
    right: 5px;
    width: 24px;
    height: 24px;
    top: 5px;
  }
}

.agent_bonus_popup {
  width: clamp(50%, 430px, 90%);
}

.bonus_agent_table {
  width: 100%;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  position: relative;
  .bonus_agent_header {
    // display: flex;
    height: 50px;
    // align-items: center;
    // border-bottom: 1px solid #cdcdcd;
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    width: 100%;
    .row_index {
      width: 50px;
      text-align: center;
      justify-content: center;
    }
  }
  .bonus_agent_list_body {
    height: 250px;
    overflow-y: auto;
    .bonus_agent_list_row {
      display: flex;
      height: fit-content;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #cdcdcd;
      .row_index {
        width: 50px;
        text-align: center;
        justify-content: center;
      }
    }
  }
}
.agent_card {
  display: flex;
  row-gap: 1rem;
  width: 100%;
  align-items: center;
  .agent_container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 100px);
    column-gap: 1.5rem;
    margin-inline-start: 1rem;
    .agent_code {
      font-weight: 500;
      font-size: 13px;
      color: #8d8686;
      text-wrap: nowrap;
      overflow: hidden;
    }
    .agent_name {
      font-size: 0.87rem;
      font-weight: bold;
      color: #747474;
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.agent_info_card {
  display: flex;
  row-gap: 1rem;
  width: 100%;
  align-items: center;
  .agent_info_container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 100px);
    column-gap: 1.5rem;
    margin-inline-start: 1rem;
    .agent_code {
      font-size: 0.8rem;
      color: #696969;
    }
    .agent_name {
      font-size: 1rem;
      font-weight: medium;
    }
    // row-gap: 1rem;
  }
}

.survey_question_card {
  margin: 1rem 0;
  .survey_response_count {
    font-size: 0.8rem;
    color: #696969;
  }
  .survey_response_text {
    min-height: 35px;
    width: 100%;
    background-color: #eaeaea;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    padding: 8px 15px;
  }
}

.response_question_body {
  max-height: 350px;
  height: fit-content;
  overflow-y: auto;
  &.summary {
    max-height: 200px;
  }
  &.pie_chart {
    height: 340px;
  }

  .survey_question_options {
    // margin-bottom: 1rem;
    padding: 0.8rem 1rem;
    .survey_list_type {
      display: flex;
      row-gap: 4px;
      align-items: center;
      width: 100%;
      .radio {
        width: 18px;
        height: 18px;
        border: 2px solid #cdcdcd;
        border-radius: 50%;
        margin-inline-end: 0.5rem;
      }
      .checkbox {
        width: 18px;
        height: 18px;
        border: 2px solid #cdcdcd;
        border-radius: 5px;
        margin-inline-end: 0.5rem;
      }
    }
    .response_count {
      font-size: 1rem;
      margin-inline-start: 30px;
      margin-top: 0.5rem;
      color: #696969;
    }
  }
}

.notification_content {
  width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: auto;
}

.remove_close_icon {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 20px;
  background-color: white;
  z-index: 10;
}

.survey_excel_export {
  height: 30px;
  min-width: 24px;
  line-height: 0;
  border-radius: 6px;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  text-transform: capitalize;
  padding: 0px 12px;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 171, 85);
  border: none;
}

.large_checkbox {
  width: 50px;
  height: 50px;
}

.bonus_agent_table_filter {
  display: flex;
  margin-bottom: 5px;
}

.sale_create {
  color: #fff;
  font-weight: 500;
  background-image: linear-gradient(310deg, #17ad37, #98ec2d);
}

.sale_update {
  color: #fff;
  font-weight: 500;
  background-image: linear-gradient(310deg, #2196f3, #b2d4ef);
}

.sale_delete {
  color: #be2516;
  background-color: #ff8e99;
}

.month_picker {
  width: 100%;
}

/** Date picker change css */
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  padding: 0.5rem;
  margin: 2px;
  width: 4rem;
  display: inline-block;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.proudct_qr_card_container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  // inset: 0;
  z-index: 4000;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  top: 0;
  left: 0;
  left: 8000px;
  .product_qr_card {
    width: 1080px;
    height: 1920px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    .thai_flag {
      position: absolute;
      top: 0;
      right: 0;
      height: 200px;
    }
  }
  .product_qr_image_container {
    width: 637px;
    height: 637px;
    background-color: white;
    border-radius: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .qr_code_product_name {
    color: #524242;
    width: 100%;
    text-align: center;
    bottom: 24%;
    font-size: 40px;
    position: absolute;
  }
}

.file_upload {
  width: 450px;
  height: 35px;
  background-color: #d8dee3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
}

.react-pdf__Page__canvas {
  width: 380px !important;
  height: 247px !important;
  border-radius: 8px;
  background-color: rgb(244, 246, 248);
  border: 1px dashed rgba(145, 158, 171, 0.32);
}

.react-pdf__Document {
  width: 380px;
  height: 247px;
}

.react-pdf__Page {
  width: 380px !important;
  height: 247px !important;
}

.react-pdf__message--loading {
  width: 380px;
  height: 247px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file_preview {
  width: 380px;
  height: 247px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 8px;
  border-radius: 8px;
  background-color: rgb(244, 246, 248);
  border: 1px dashed rgba(145, 158, 171, 0.32);
  position: relative;
}

.file_preview:hover {
  cursor: pointer;
}

.pdf_preview {
  width: 95x;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf_container {
  position: relative;
  width: fit-content;
  height: auto;
}

.button_align {
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px;
}

.close__btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  border-radius: 50%;
  overflow: visible;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.125rem;
  top: 10px;
  padding: 2px;
  right: 6px;
  position: absolute;
  color: rgb(255, 255, 255);
  background-color: rgba(22, 28, 36, 0.72);
}

.close__icon {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  inset: 0px;
  border-radius: inherit;
}

.download_btn {
  position: absolute;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  border-radius: 50%;
  overflow: visible;
  font-size: 1.125rem;
  top: -9px;
  padding: 5px;
  right: -7px;
  color: rgb(255, 255, 255);
}

@media (max-width: 600px) {
  // .button_align {
  //   width: 305px;
  // }

  .file_preview {
    width: 305px;
    height: 247px;
  }

  .react-pdf__Page__canvas {
    width: 305px !important;
    height: 247px !important;
    border-radius: 8px;
    background-color: rgb(244, 246, 248);
    border: 1px dashed rgba(145, 158, 171, 0.32);
  }

  .react-pdf__Document {
    width: 305px;
    height: 247px;
  }

  .react-pdf__Page {
    width: 305px !important;
    height: 247px !important;
  }

  .react-pdf__message--loading {
    width: 305px;
    height: 247px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 320px) {
  .file_preview {
    width: 266px;
  }

  .react-pdf__Page__canvas {
    width: 266px !important;
  }

  .react-pdf__Document {
    width: 266px !important;
  }

  .react-pdf__Page {
    width: 266px !important;
  }

  .react-pdf__message--loading {
    width: 266px;
  }
}

.btn_success.create_group {
  text-decoration: none;
  border-radius: 0.5rem;
}

.table_error_text {
  position: absolute;
  left: 0;
}

.image_center {
  display: flex;
  justify-content: center;
}

.bonus_frame_upload_image {
  width: 100%;
  height: 100%;
}

// simple-react-month-picker
.pPCtx {
  left: 0;
}

.bonus_strong {
  font-size: 0.875rem !important;
  font-family: Roboto, sans-serif;
  color: #616161 !important;
}

.bonus_span {
  font-size: 0.875rem !important;
  margin-left: 10px;
}

.table_scroll {
  overflow-y: scroll;
}

.table_header {
  flex-shrink: 0;
}

.td_width {
  position: relative;
  width: 13.5%;
}

.td_small_width {
  position: relative;
  width: 14.3%;
}

.email_style {
  padding: 8px;
  border-radius: 10px;
  background-color: rgb(117, 103, 242);
  margin: 5px;
  color: white;
}

.outer_wrapper {
  position: relative;
  height: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.custom_scroll {
  position: relative;
  // height: 100%;
  // width: 100%;
  overflow: auto;
}

.custom_scroll::-webkit-scrollbar {
  width: 12px;
  padding-top: 10px;
}

.custom_scroll::-webkit-scrollbar-track {
  border-radius: 100px;
}

.custom_scroll::-webkit-scrollbar-thumb {
  background-color: #b1b7c1;
  height: 20px;
  border: 3px solid #ffffff;
  border-radius: 100px;
}

.agent_level {
  width: fit-content;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  padding: 0 9px;
  // margin: auto;
}

.status_circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 10px;
}

.list_level_status {
  font-size: 14px;
  margin-right: 10px;
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.agent_level {
  &.active_agent {
    background-color: #d1fadf;
    color: #14b86d;
  }
  &.exclusive_agent {
    background-color: #d2e0ff;
    color: #1261ff;
  }
  &.top_50_agent {
    background-color: #fff0cc;
    color: #cd881f;
  }
  &.top_24_agent {
    background-color: #f5ebff;
    color: #9b72ed;
  }
  &.unicorn_agent {
    background-color: #cff9fe;
    color: #04aed4;
  }
  // &.active_exclusice_agent {
  //   background-color: #ffddd9;
  //   color: #d95f52;
  // }
  // &.global_top_agent {
  //   background-color: #f8f6fe;
  //   color: #7449d6;
  // }
  // &.normal_agent {
  //   background-color: #7f7f7f;
  //   color: #fefefe;
  // }
}

.status_circle {
  &.active_agent {
    background-color: #14b86d;
    // color: #14b86d;
  }
  &.exclusive_agent {
    background-color: #1261ff;
    // color: #1261ff;
  }
  &.top_50_agent {
    background-color: #cd881f;
    // color: #ffcd50;
  }
  &.top_24_agent {
    background-color: #9b72ed;
    // color: #9b72ed;
  }
  &.unicorn_agent {
    background-color: #04aed4;
    // color: #04aed4;
  }
  // &.global_top_agent {
  //   background-color: #7449d6;
  //   // color: #7449d6;
  // }
  // &.normal_agent {
  //   background-color: #7f7f7f;
  //   //color: #fefefe;
  // }
  // &.active_exclusice_agent {
  //   background-color: #d95f52;
  //   // color: #ff7a6b;
  // }
}

.sum_total_amount {
  text-align: right;
  font-weight: bold !important;
}

.search_bar {
  position: relative;
}

.search_bar_wrapper {
  background-color: #7a78784d;
  width: 95%;
  border-radius: 10px;
  min-height: 35px;
  padding: 0 15px;
  // box-shadow: 3px 2px 10px #9e9191bd;
  border: 1px solid #1d273a;
  display: flex;
  align-items: center;
  margin: 5px 5px 5px 5px;
}

.search_bar_wrapper:focus-within {
  border: 1px solid white;
}

.search_input,
.search_input:hover,
.search_input:focus,
.search_input:active {
  background-color: transparent;
  border: none;
  color: #fff;
  height: 100%;
  width: 100%;
  margin-left: 10px;
  outline: 0px !important;
}

.search_input:hover {
  font-size: small;
}

.search_result_list {
  position: absolute;
  margin-left: 3px;
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto;
  font-size: 0.875rem !important;
  font-weight: 400;
  width: 96%;
  padding: 5px 5px 5px 0px;
  background-color: #1c293f;
  color: #fff;
  box-shadow: 0px 0px 5px #799fe0;
  border-radius: 10px;
  z-index: 50;
}

.result_wrapper {
  max-height: 200px;
  padding: 3px 0px 3px 0px;
  overflow: auto;
}

.result_wrapper::-webkit-scrollbar {
  width: 5px;
  padding-top: 10px;
}

.result_wrapper::-webkit-scrollbar-track {
  // width: 1px;
  border-radius: 100px;
  // background-color: red;
}

.result_wrapper::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #5f759a;
  border-radius: 100px;
}

.search_result {
  position: relative;
  padding: 6px 3px 6px 10px;
  border-radius: 7px;
}

.search_result:hover {
  background-color: #31486d;
  box-shadow: 0px 0px 5px white;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 3px;
}

.simplebar::-webkit-scrollbar {
  width: 12px;
  padding-top: 10px;
}

.simplebar::-webkit-scrollbar-track {
  border-radius: 100px;
}

.simplebar::-webkit-scrollbar-thumb {
  background-color: #b1b7c1;
  height: 20px;
  border: 3px solid #3c4b64;
  border-radius: 100px;
}

.light-gray-hover:hover {
  background-color: lightgray;
  cursor: pointer;
}

.border-bot-0 {
  border-bottom: none !important;
}

.product_group_container {
  display: flex;
  justify-content: flex-end;
}

.product_group_container__button,
.product_group_container__button:hover,
.product_group_container__button:focus,
.product_group_container__button:active {
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  box-shadow: rgb(0 171 85 / 24%) 0px 8px 16px 0px !important;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 171, 85);
  outline: 0px;
  border: 0px;
  margin: 0px;
  padding: 7px 16px;
}

.create_button_container {
  display: flex;
  justify-content: flex-end;
}

.create_button_container__button,
.create_button_container__button:hover,
.create_button_container__button:focus,
.create_button_container__button:active {
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  box-shadow: rgb(0 171 85 / 24%) 0px 8px 16px 0px !important;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 171, 85);
  outline: 0px;
  border: 0px;
  margin: 0px;
  padding: 7px 16px;
}

.detail_table_body {
  display: table;
  width: 56%;
  border-collapse: collapse;
  border-spacing: 0px;
  margin: auto;
}

.detail_table_body td {
  border-bottom: none;
}

.detail_table_body > tbody > tr {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0px;
  height: 50px;
  border: 1px solid #c5bdbd52;
}

.detail_table_row_title {
  font-size: 0.875rem;
  color: rgb(33, 33, 33);
  font-weight: 500;
  width: 310px;
}

.detail_table_row_data {
  letter-spacing: 0em;
  font-weight: 400;
  line-height: 1.5em;
  color: rgb(97, 97, 97);
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgb(238, 238, 238);
  text-align: left;
  padding: 6px 16px;
  border-top-color: rgb(238, 238, 238);
  border-right-color: rgb(238, 238, 238);
  border-left-color: rgb(238, 238, 238);
}
